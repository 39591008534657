/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const cognitoUser = /* GraphQL */ `
  query CognitoUser {
    cognitoUser {
      Username
      UserAttributes {
        Name
        Value
      }
      PennUserAttributes {
        penn_id
        affiliations
        name
        preferred_username
        given_name
        family_name
        email
      }
    }
  }
`;
export const interfolioUser = /* GraphQL */ `
  query InterfolioUser {
    interfolioUser {
      cognitoUsername
      sso_id
      id
      first_name
      last_name
      email
      pid
      committee_member_committees {
        id
        unit_id
        name
        type
        committee_member_id
        manager
      }
      titles {
        id
        name
        unit_id
        unit_name
        title_association_id
        can_be_deleted
      }
      departments {
        id
        name
        ancestry_depth
        ancestor_institution_id
        parent_unit_id
        child_unit_ids
      }
      appointmentPermission
      lastCaseKickoffLogin
      highScore
      publishHighScore
      createdAt
      updatedAt
    }
  }
`;
export const interfolioUnits = /* GraphQL */ `
  query InterfolioUnits {
    interfolioUnits {
      id
      name
      ancestry_depth
      ancestor_institution_id
      parent_unit_id
      child_unit_ids
    }
  }
`;
export const pcomSearch = /* GraphQL */ `
  query PcomSearch($searchTerm: String) {
    pcomSearch(searchTerm: $searchTerm) {
      penn_id
      kerberos_principal
      first_name
      middle_name
      last_name
      email
      description
    }
  }
`;
export const interfolioUserByEmail = /* GraphQL */ `
  query InterfolioUserByEmail($email: String) {
    interfolioUserByEmail(email: $email) {
      cognitoUsername
      sso_id
      id
      first_name
      last_name
      email
      pid
      committee_member_committees {
        id
        unit_id
        name
        type
        committee_member_id
        manager
      }
      titles {
        id
        name
        unit_id
        unit_name
        title_association_id
        can_be_deleted
      }
      departments {
        id
        name
        ancestry_depth
        ancestor_institution_id
        parent_unit_id
        child_unit_ids
      }
      appointmentPermission
      lastCaseKickoffLogin
      highScore
      publishHighScore
      createdAt
      updatedAt
    }
  }
`;
export const interfolioUserCases = /* GraphQL */ `
  query InterfolioUserCases($from: Int, $size: Int, $onlyActive: Boolean) {
    interfolioUserCases(from: $from, size: $size, onlyActive: $onlyActive) {
      total
      data {
        id
        firstname
        lastname
        email
        unit_name
        created_date
        closed_date
        status
        closed
        formatted_status
        current_workflow_step_name
        template_name
        type
      }
    }
  }
`;
export const interfolioCaseDetail = /* GraphQL */ `
  query InterfolioCaseDetail($caseId: Int) {
    interfolioCaseDetail(caseId: $caseId) {
      id
      candidate_first_name
      candidate_last_name
      candidate_email
      archived
      candidate_involvement
      applicant_pid
      allow_additional_documents
      unit_id
      created_at
      due_date
      packet_template_name
      packet_type_id
      institution_id
      applicant_id
      current_workflow_step {
        id
        step_number
        current
        due_date
        created_at
        name
        note
        committees {
          id
          name
          committee_member_count
          type
          standing
          unit_id
        }
      }
      unit_name
      status
      current_users_assigned_committees {
        id
        name
      }
      workflow_steps {
        id
        step_number
        current
        due_date
        created_at
        name
        note
        committees {
          id
          name
          committee_member_count
          type
          standing
          unit_id
        }
      }
      next_workflow_step {
        id
        step_number
        current
        due_date
        created_at
        name
        note
        committees {
          id
          name
          committee_member_count
          type
          standing
          unit_id
        }
      }
      previous_workflow_step {
        id
        step_number
        current
        due_date
        created_at
        name
        note
        committees {
          id
          name
          committee_member_count
          type
          standing
          unit_id
        }
      }
      can_be_moved_forward
      waiting_on_committee
    }
  }
`;
export const subjectAreas = /* GraphQL */ `
  query SubjectAreas {
    subjectAreas {
      code
      description
    }
  }
`;
export const reappointments = /* GraphQL */ `
  query Reappointments {
    reappointments {
      reappointmentId
      pennId
      pennkey
      email
      lastName
      firstName
      orgDescr
      orgDescrShort
      interfolioJobTitle
      jobTitleShort
      interfolioUnitId
      isCurrent
      endDate
      startDate
      yearsAtRank
      fullPartTime
      nextTerm
      maxReappointmentTerm
      defaultEndTerm
      reappointmentLength
    }
  }
`;
export const courseHistory = /* GraphQL */ `
  query CourseHistory($subject: String, $course_num: String) {
    courseHistory(subject: $subject, course_num: $course_num) {
      crse_section_pk
      term
      course_ref_num
      section_id
      subject
      course_num
      section_num
      title
      total_enrlmt
      division
      schedule_type
    }
  }
`;
export const positionHistory = /* GraphQL */ `
  query PositionHistory($workerPennId: String) {
    positionHistory(workerPennId: $workerPennId) {
      workerPennId
      jobProfileName
      occupantFromYear
      primaryPositionFlag
    }
  }
`;
export const instructorCourseSections = /* GraphQL */ `
  query InstructorCourseSections($instructor_penn_id: String) {
    instructorCourseSections(instructor_penn_id: $instructor_penn_id) {
      crse_sec_instructor_pk
      term
      course_ref_num
      subject
      course_num
      section_num
      instructor_penn_id
      instructor_last_name
      instructor_first_name
      instructor_mi
      responsibility_percent
      session_percent
      primary_instructor
      course_section {
        crse_section_pk
        term
        course_ref_num
        section_id
        subject
        course_num
        section_num
        title
        total_enrlmt
        division
        schedule_type
      }
    }
  }
`;
export const interfolioUnitMapping = /* GraphQL */ `
  query InterfolioUnitMapping {
    interfolioUnitMapping {
      unit_id
      workday_unit_id
      orgCodes
      lpsUnit
      divisions
      businessOffice
      defaultSubjectArea
    }
  }
`;
export const actionAndDocumentTypes = /* GraphQL */ `
  query ActionAndDocumentTypes {
    actionAndDocumentTypes {
      titleActions {
        actionType
        isFulltime
        provostApproval
        title
        specialCase
        documents {
          documentTypeCode
          minAllowed
          maxAllowed
        }
        canCreateFacultyCommittee
        canOmitFunding
        canOmitCourses
        canOmitEndTerm
        hideFunding
        hideCourses
        canEnterLPS
        canEnterLanguageInstruction
        canEnterVisaInfo
        canEnterRankStartDate
        positionSpanEntryType
        canEnterCitizenship
        canEnterDegreeCompletion
        kickoffType
        offerLetterType
      }
      documentTypes {
        FAC_CV {
          code
          name
          nameShort
          instructions
        }
        TEACHING_EVALS {
          code
          name
          nameShort
          instructions
        }
        DEPT_LETTER {
          code
          name
          nameShort
          instructions
        }
        REC_LETTER {
          code
          name
          nameShort
          instructions
        }
        REC_LETTER_PENN {
          code
          name
          nameShort
          instructions
        }
        REC_LETTER_EITHER {
          code
          name
          nameShort
          instructions
        }
        SENIOR_LFL_RECOMMENDATION_PENN {
          code
          name
          nameShort
          instructions
        }
        LFL_RECOMMENDATION_PENN {
          code
          name
          nameShort
          instructions
        }
        LFL_RECOMMENDATION_EXTRAMURAL {
          code
          name
          nameShort
          instructions
        }
        CRIT_WRI_COMM_RPT {
          code
          name
          nameShort
          instructions
        }
        EXMUR_CONSULT_LIST {
          code
          name
          nameShort
          instructions
        }
        ENG_FLUENCY_CERT {
          code
          name
          nameShort
          instructions
        }
        EEO_REPORT {
          code
          name
          nameShort
          instructions
        }
        SELF_ID_FORM {
          code
          name
          nameShort
          instructions
        }
        OTHER {
          code
          name
          nameShort
          instructions
        }
        DRAFT_OFFER_LET {
          code
          name
          nameShort
          instructions
        }
        SIGNED_OFFER_LET {
          code
          name
          nameShort
          instructions
        }
        SEC_APPROVAL_LETTER_PRIMARY {
          code
          name
          nameShort
          instructions
        }
        SEC_APPROVAL_LETTER_SECONDARY {
          code
          name
          nameShort
          instructions
        }
        DIST_OF_CURRENT_FACULTY {
          code
          name
          nameShort
          instructions
        }
        CONTRIBUTION_STATEMENT {
          code
          name
          nameShort
          instructions
        }
        PEDAGOGY_STATEMENT {
          code
          name
          nameShort
          instructions
        }
      }
    }
  }
`;
export const interfolioLecturerInfo = /* GraphQL */ `
  query InterfolioLecturerInfo(
    $page: Int
    $pageLength: Int
    $requestedPacketIds: [Int]
  ) {
    interfolioLecturerInfo(
      page: $page
      pageLength: $pageLength
      requestedPacketIds: $requestedPacketIds
    ) {
      pageLength
      page
      totalCount
      info {
        packetId
        candidateName
        submittedDate
        title
        actionType
        startTerm
        endTerm
        lps
        courseId1
        courseTerm1
        courseInfo1
        courseId2
        courseTerm2
        courseInfo2
        courseId3
        courseTerm3
        courseInfo3
        courseAdditionalInfo
        startDate
        endDate
        visaType
        rankStartDate
      }
    }
  }
`;
export const cpsCourses = /* GraphQL */ `
  query CpsCourses($srsTerm: String) {
    cpsCourses(srsTerm: $srsTerm) {
      id
      interfolioUnitId
      orgCode
      unitName
      firstName
      middleName
      lastName
      email
      pennId
      pennkey
      termCode
      subjectArea
      courseNumber
      srsTerm
      numSections
    }
  }
`;
export const systemStatus = /* GraphQL */ `
  query SystemStatus {
    systemStatus {
      status
      message
    }
  }
`;
export const facultyAppointments = /* GraphQL */ `
  query FacultyAppointments($pennId: String) {
    facultyAppointments(pennId: $pennId) {
      id
      titleGenerated
      jobTitle
      jobTitleShort
      orgDescription
      apptStartDate {
        date
        timezone_type
        timezone
      }
      apptEndDate {
        date
        timezone_type
        timezone
      }
      standingFaculty
      fulltimeTeaching
    }
  }
`;
export const getInterfolioUser = /* GraphQL */ `
  query GetInterfolioUser($cognitoUsername: String!) {
    getInterfolioUser(cognitoUsername: $cognitoUsername) {
      cognitoUsername
      sso_id
      id
      first_name
      last_name
      email
      pid
      committee_member_committees {
        id
        unit_id
        name
        type
        committee_member_id
        manager
      }
      titles {
        id
        name
        unit_id
        unit_name
        title_association_id
        can_be_deleted
      }
      departments {
        id
        name
        ancestry_depth
        ancestor_institution_id
        parent_unit_id
        child_unit_ids
      }
      appointmentPermission
      lastCaseKickoffLogin
      highScore
      publishHighScore
      createdAt
      updatedAt
    }
  }
`;
export const listInterfolioUsers = /* GraphQL */ `
  query ListInterfolioUsers(
    $cognitoUsername: String
    $filter: ModelInterfolioUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInterfolioUsers(
      cognitoUsername: $cognitoUsername
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        cognitoUsername
        sso_id
        id
        first_name
        last_name
        email
        pid
        committee_member_committees {
          id
          unit_id
          name
          type
          committee_member_id
          manager
        }
        titles {
          id
          name
          unit_id
          unit_name
          title_association_id
          can_be_deleted
        }
        departments {
          id
          name
          ancestry_depth
          ancestor_institution_id
          parent_unit_id
          child_unit_ids
        }
        appointmentPermission
        lastCaseKickoffLogin
        highScore
        publishHighScore
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCaseKickoff = /* GraphQL */ `
  query GetCaseKickoff($id: ID!) {
    getCaseKickoff(id: $id) {
      id
      data {
        candidateEmail
        candidateFirstName
        candidateLastName
        candidatePennId
        candidatePennkey
        interfolioCaseId
        adminOrg
        division
        startTerm
        endTerm
        caseAction
        title
        positionDesc
        lps
        lpsUnit
        businessOffice
        langInstructor
        overrideReapp
        proposedSal
        fundingSource
        fundingNotes
        approvedBudgetAmt
        budgetFiscalYear
        budgetFundCode
        cref
        allCourseNotes
        courses {
          row
          subjectArea
          courseNum
          courseTerm
          numSecs
          avgEnroll
        }
        departmentName
        hasFunding
        hasCourses
        visaRequired
        visaType
        rankStartDate
        startDate
        endDate
        creatorUsername
        facultyCommitteeRankCodes
        degreeCompleted
        degreeCompletedDate
        citizenship
        offerLetterText
      }
      status
      interfolioId
      warnings {
        message
        detail
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCaseKickoffs = /* GraphQL */ `
  query ListCaseKickoffs(
    $filter: ModelCaseKickoffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCaseKickoffs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        data {
          candidateEmail
          candidateFirstName
          candidateLastName
          candidatePennId
          candidatePennkey
          interfolioCaseId
          adminOrg
          division
          startTerm
          endTerm
          caseAction
          title
          positionDesc
          lps
          lpsUnit
          businessOffice
          langInstructor
          overrideReapp
          proposedSal
          fundingSource
          fundingNotes
          approvedBudgetAmt
          budgetFiscalYear
          budgetFundCode
          cref
          allCourseNotes
          departmentName
          hasFunding
          hasCourses
          visaRequired
          visaType
          rankStartDate
          startDate
          endDate
          creatorUsername
          facultyCommitteeRankCodes
          degreeCompleted
          degreeCompletedDate
          citizenship
          offerLetterText
        }
        status
        interfolioId
        warnings {
          message
          detail
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
